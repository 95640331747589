import React, { useEffect, useState } from "react";
import { ApiHandler } from "ApiHandler";
import { STEPS } from "App";

const TopSection = ({ showStep, selection }) => {
  const [imgSrc, setImageSrc] = useState("/car.png");

  useEffect(() => {
    if (selection.model) {
      setImageSrc(ApiHandler.getCarImage(selection.make, selection.model));
    }
  }, [selection.model]);

  return (
    <div>
      <div className="row middle">
        <div>
          <div className="title" style={{ textAlign: "center"}}>
          Leasing an EV is the best deal around. Save $251/mo. through federal tax credits
          </div>
        <div>
        <div className="row" style={{ margin: "14px 0 14px 0"}}>
          <div className="column">
            <div className="big_number text-black">
              $511/mo
            </div>
            <div style={{ fontSize: 12 }}>With federal tax credit</div>
          </div>
          <div className="column">
            <div
              className="big_number text-gray"
              style={{ textDecoration: "line-through" }}
            >
              $762*
            </div>
            <div style={{ fontSize: 12 }}>
              *Without federal tax credit
            </div>
          </div>
        </div>
        <div className="disclaimer">
          *Showing price data for 2024 Hyundai IONIQ 5. Federal tax credit based on eligibility. To learn more about requirements,{" "}
          <a className="link" href="https://www.nerdwallet.com/article/taxes/ev-tax-credit-electric-vehicle-tax-credit" target="_blank" rel="noreferrer">click here</a>
        </div>
      </div>
        </div>
        <div className="car_image desktop">
          <img onError={() => setImageSrc("/car.png")} src={imgSrc} alt="" />
        </div>
      </div>
      <div className="car_image mobile">
        <img onError={() => setImageSrc("/car.png")} src={imgSrc} alt="" />
      </div>
    </div>
  );
};

export default TopSection;
