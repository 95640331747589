import React, { useState, useEffect } from "react";
import {
  Button,
  InputLabel,
  TextField,
  FormHelperText,
  ThemeProvider,
  Select,
  MenuItem,
} from "@mui/material";
import CurrencyTextField from "@lupus-ai/mui-currency-textfield";
import { APP_THEME, STEPS, INPUT_PLACEHOLDER } from "App";
import { ApiHandler } from "ApiHandler";
import { CARS_DATA_ARRAY } from "data/2024 EV Data - EV Data";
import RevoLogo from "components/RevoLogo";

function ZipCode({
  setShowStep,
  setSelection,
  selection,
  setDealers,
  setUserInfo,
  lease,
  setLease,
}) {
  const [errorpostalCode, setErrorPostalCode] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [noDealers, setNoDealers] = useState(false);
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);

  const postalCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;

  useEffect(() => {
    const uniqueMakes = [
      ...new Set(CARS_DATA_ARRAY.map((car) => car.make)),
    ].map((make) => ({ value: make, label: make }));
    setMakes(uniqueMakes);

    // set default selection
    if (!selection.make) {
      setSelection({ make: "Hyundai", model: "Ioniq 5" });
    }
  }, []);

  useEffect(() => {
    const modelsPerMake = [
      ...new Set(
        CARS_DATA_ARRAY.filter((car) => car.make === selection.make).map(
          (car) => car.model
        )
      ),
    ]
      .map((model) => ({ value: model, label: model }))
      .sort((a, b) => (a.label > b.label ? 1 : -1));
    if (modelsPerMake.length === 0) {
      setModels([{ value: "", label: "Select Make first" }]);
    } else {
      setModels(modelsPerMake);
      setSelection({ ...selection, model: modelsPerMake[0].value });
    }
  }, [selection.make]);

  useEffect(() => {
    const selectedPrice =
      CARS_DATA_ARRAY.find(
        (car) => car.make === selection.make && car.model === selection.model
      )
        ?.price.replace("$", "")
        .replace(",", "") || 0;
    setLease({ ...lease, price: Number(selectedPrice) });
  }, [selection.model]);

  useEffect(() => {
    setError("");
    setNoDealers(false);
  }, [selection]);

  const handleSubmit = () => {
    if (!selection.make || !selection.model) {
      setError("Please fill out all required fields.");
      return;
    }
    setError("");
    if (!postalCodeRegex.test(selection.postalCode)) {
      setErrorPostalCode(true);
      return;
    }

    setLoading(true);

    setError("");
    setErrorPostalCode(false);

    ApiHandler.getCloseDealers(selection).then((data) => {
      setLoading(false);
      if (data && data.length > 0) {
        setDealers(data);
        setUserInfo((prev) => ({ ...prev, selected: data.map((d) => d.uuid) }));
        setShowStep(STEPS.USER_INFO);
      } else {
        setNoDealers(true);
      }
    });
  };
  return (
    <ThemeProvider theme={APP_THEME}>
      <div className="container blue-step">
        <div className="title">
          Leasing an EV is a great deal with the Federal Tax Credit
        </div>
        <div className="subtitle">
          Select an EV and enter your lease preferences to check your monthly
          payment with the EV tax credit applied
        </div>
        <div>
          <div className="row-stretch">
            <div style={{ width: "100%" }}>
              <InputLabel className="label">Make</InputLabel>
              <Select
                style={{ width: "100%" }}
                id="user-car-make"
                sx={INPUT_PLACEHOLDER}
                value={selection.make}
                onChange={(e) =>
                  setSelection({ ...selection, make: e.target.value })
                }
              >
                {makes.map((make, index) => (
                  <MenuItem key={index} value={make.value}>
                    {make.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div style={{ width: "100%" }}>
              <InputLabel className="label">Model</InputLabel>
              <Select
                style={{ width: "100%" }}
                id="user-car-model"
                sx={INPUT_PLACEHOLDER}
                value={selection.model}
                onChange={(e) => {
                  setSelection({ ...selection, model: e.target.value });
                  window.scrollTo({ top: 400, behavior: "smooth" });
                }}
              >
                {models.map((model, index) => (
                  <MenuItem key={index} value={model.value}>
                    {model.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

          <div className="row-stretch">
            <div style={{ width: "100%" }}>
              <InputLabel className="label">Purchase Price</InputLabel>
              <CurrencyTextField
                style={{ width: "100%" }}
                variant="outlined"
                decimalPlaces={0}
                value={lease.price}
                onChange={(_, v) => setLease({ ...lease, price: v })}
              />
            </div>
            <div style={{ width: "100%" }}>
              <InputLabel className="label">Down Payment</InputLabel>
              <CurrencyTextField
                style={{ width: "100%" }}
                id="user-lease-downpayment"
                variant="outlined"
                decimalPlaces={0}
                value={lease.downPayment}
                onChange={(_, v) => setLease({ ...lease, downPayment: v })}
              />
            </div>
          </div>

          <div className="row-stretch">
            <div style={{ width: "100%" }}>
              <InputLabel className="label">Trade In Value</InputLabel>
              <CurrencyTextField
                style={{ width: "100%" }}
                variant="outlined"
                decimalPlaces={0}
                value={lease.tradeInValue}
                onChange={(_, v) => setLease({ ...lease, tradeInValue: v })}
              />
            </div>
            <div style={{ width: "100%" }}>
              <InputLabel className="label">Zip Code</InputLabel>
              <TextField
                style={{ width: "100%" }}
                id="user-zip-code"
                placeholder="12345"
                value={selection.postalCode}
                onChange={(e) => {
                  setError("");
                  setErrorPostalCode(false);
                  setSelection({ ...selection, postalCode: e.target.value });
                }}
                onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
              />
              {errorpostalCode && (
                <FormHelperText>Invalid Zip Code</FormHelperText>
              )}
            </div>
          </div>
        </div>

        <div className="row-stretch">
          {error && <div className="error">{error}</div>}
          <Button
            className="submit-button"
            variant="contained"
            color="custom_black"
            sx={{ fontWeight: "bold", padding: 1.5, marginTop: "20px" }}
            onClick={handleSubmit}
          >
            {loading ? (
              <div className="spinner"></div>
            ) : (
              "CALCULATE YOUR LEASE PAYMENT"
            )}
          </Button>
        </div>

        {noDealers && (
          <div className="row center subtitle">
            We couldn’t find any nearby dealerships for that Make and Model.
            Please update the vehicle or try another zip code.
          </div>
        )}
        <div>
          <RevoLogo />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default ZipCode;
