import React, { useEffect, useState } from "react";
import "App.css";
import ScreenSelectMaker from "screens/SelectMake";
import ScreenUserInfo from "screens/UserInfo";
import ScreenSubmitted from "screens/Submitted";
import TopSection from "components/TopSection";
import Status from "screens/Status";
import { createTheme } from "@mui/material";
import TopSectionCalc from "screens/calculator/TopSectionCalc";
import ZipCode from "screens/calculator/Step1Calculator";

const isCalculator = window.location.href.includes("calculator");

const STEPS = {
  SELECT_MAKE: 0,
  USER_INFO: 1,
  SUBMITTED: 2,
};

const APP_THEME = createTheme({
  palette: {
    primary: {
      main: "#2081F3",
    },
    custom_black: {
      main: "#1A1A1A",
      light: "#1A1A1A",
      dark: "#1A1A1A",
      contrastText: "white",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
  },
});

const INPUT_PLACEHOLDER = {
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: isCalculator ? "#1A1A1A" : "",
  },
  "& .MuiSelect-select .notranslate::after": "Select"
    ? { content: '"Select"' }
    : {},
};

function App() {
  const [showStep, setShowStep] = useState(isCalculator ? STEPS.SELECT_MAKE : STEPS.USER_INFO);
  const [selection, setSelection] = useState({
    make: "",
    model: "",
    postalCode: "",
  });
  const [lease, setLease] = useState({
    downPayment: 0,
    tradeInValue: 0,
    price: 0,
  });
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    selected: [],
  });
  const [dealers, setDealers] = useState([]);

  useEffect(() => {
    if ("parentIFrame" in window) {
      window.parentIFrame.sendMessage("resize");
    }
  }, [showStep]);

  if (window.location.href.includes("status")) {
    return <Status />;
  }

  if (isCalculator) {
    document.title = "rEVo Leasing Calculator";
    return (
      <div className="calculator_wrapper">
        <div className="column">
          <div className="steps_section">
            {showStep === STEPS.SELECT_MAKE && (
              <ZipCode
                setShowStep={setShowStep}
                setSelection={setSelection}
                selection={selection}
                lease={lease}
                setLease={setLease}
                setDealers={setDealers}
                setUserInfo={setUserInfo}
              />
            )}
            {showStep === STEPS.USER_INFO && (
              <div>
                <div className="row-spacer" />
                <TopSectionCalc lease={lease} />
                <ScreenUserInfo
                  calculator={true}
                  selection={selection}
                  setShowStep={setShowStep}
                  userInfo={userInfo}
                  setUserInfo={setUserInfo}
                  dealers={dealers}
                  isCalculator
                />
              </div>
            )}
            {showStep === STEPS.SUBMITTED && (
              <ScreenSubmitted
                setShowStep={setShowStep}
                selection={selection}
                setSelection={setSelection}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  document.title = "rEVo Find a Dealer";
  return (
    <div className="wrapper">
      {showStep === STEPS.USER_INFO && (
        <div className="top_section">
          <TopSection showStep={showStep} selection={selection} />
        </div>
      )}
      <div className="steps_section">
        {showStep === STEPS.USER_INFO && (
          <ScreenUserInfo
            calculator={false}
            selection={selection}
            setShowStep={setShowStep}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            dealers={dealers}
            isCalculator={false}
          />
        )}
        {showStep === STEPS.SUBMITTED && (
          <ScreenSubmitted
            setShowStep={setShowStep}
            selection={selection}
            setSelection={setSelection}
          />
        )}
      </div>
    </div>
  );
}

export default App;
export { STEPS };
export { APP_THEME };
export { INPUT_PLACEHOLDER };
