// Just copy the data from the google sheet (copy and paste the wanted columns)
// https://docs.google.com/spreadsheets/d/1jguNP8xd0ubyfc7M1t1TKkXkuHvl3a6ilOFFOn6sK4o/edit#gid=0
export const CARS_DATA = `
Audi	RS e-tron GT	$147,100	249	https://www.audiusa.com/us/web/en/models/e-tron-gt/e-tron-gt/2024/overview.html
Audi    e-tron GT   $106,500    249 https://www.audiusa.com/us/web/en/models/e-tron-gt/e-tron-gt/2024/overview.html
Audi	Q8 Sportback e-tron	$77,800	296	https://www.audiusa.com/us/web/en/models/q8-e-tron/q8-e-tron/2024/overview.html
Audi	Q4 Sportback e-tron	$58,200	258	https://www.audiusa.com/us/web/en/models/electric-models.html
Audi	SQ8 Sportback e-tron	$92,600	253	https://www.audiusa.com/us/web/en/models/q8-e-tron/sq8-e-tron/2024/overview.html
Audi	Q8 e-tron	$74,400	285	https://www.audiusa.com/us/web/en/models/q8-e-tron/q8-e-tron/2024/overview.html
Audi    Q4 e-tron   $55,200   258 https://www.audiusa.com/us/web/en/models/q8-e-tron/q8-e-tron/2024/overview.html
BMW	i4	$52,200	269	https://www.bmwusa.com/build-your-own.html#/series/i4/gran-coupe
BMW	iX	$87,100	296	https://www.bmwusa.com/build-your-own.html#/series/iX/sports-activity-vehicle
BMW	i7	$105,700	291	https://www.bmwusa.com/build-your-own.html#/series/i7/sedan
BMW	i5	$66,800	256	https://www.bmwusa.com/build-your-own.html#/series/i5/sedan
Cadillac	LYRIQ	$58,590	314	https://www.cadillac.com/electric/lyriq
Chevrolet	Blazer EV	$50,195	279	https://www.chevrolet.com/electric
Chevrolet	Silverado EV	$79,800	393	https://www.chevrolet.com/electric
Chevrolet	Bolt EUV	$28,795	247	https://www.chevrolet.com/electric/bolt-euv
Chevrolet	Equinox EV	$41,900	319	https://www.chevrolet.com/electric/equinox-ev
Chevrolet	Bolt EV	$26,500	259	https://www.chevrolet.com/electric/bolt-ev
FIAT	500e	$32,500	149	https://www.fiatusa.com/electric-500.html
Ford	F-150 Lightning	$62,995	300	https://www.ford.com/trucks/f150/f150-lightning/
Ford	Mustang Mach-E	$39,995	250	https://www.ford.com/suvs/mach-e/
GMC	Sierra EV Denali	$97,500	440	https://www.gmc.com/electric/sierra-ev-denali
GMC	HUMMER EV SUV	$96,550	314	https://www.gmc.com/electric/hummer-ev/suv
GMC HUMMER EV   $96,550 311 https://www.gmc.com/electric/hummer-ev/suv
Genesis	GV60	$52,000	235	https://www.genesis.com/us/en/gv60
Genesis	Electrified G80	$74,375	282	https://www.genesis.com/us/en/electrified-g80
Genesis Electrified GV70    $66,450 236 https://www.genesis.com/us/en/electrified-g70
Hyundai Kona Electric   $32,675 197 https://www.hyundaiusa.com/us/en/vehicles/
Hyundai IONIQ 5 $41,800 220 https://www.hyundaiusa.com/us/en/vehicles/
Honda	Prologue	$47,400	273	https://www.honda.com/prologue
Hyundai	IONIQ 6	$37,500	240	https://www.hyundaiusa.com/us/en/vehicles/ioniq-6
Jaguar	I-PACE	$72,000	246	https://buildyour.jaguarusa.com/jag2/r/products/_/en_us/i-pace_k24/4b4xo
Kia	EV6	$42,600	218	https://www.kia.com/us/en/ev6
Kia	EV9	$54,900	230	https://www.kia.com/us/en/ev9
Kia	Niro EV	$39,600	253	https://www.kia.com/us/en/niro-ev
Lexus	RZ	$55,240	196	https://www.lexus.com/rz
Lucid	Air	$69,900	411	https://lucidmotors.com/air
MINI	Hardtop 2 Door	$29,900	114	https://www.miniusa.com/model/electric-hardtop.html
Mercedes-Benz	EQS	$104,400	305	https://www.mbusa.com/en/vehicles/class/eqs/sedan
Mercedes-Benz	EQE	$74,900	230	https://www.mbusa.com/en/vehicles/class/eqe/sedan
Mercedes-Benz	EQB	$52,750	221	https://www.mbusa.com/en/vehicles/class/eqb
Mercedes-Benz   EQS SUV $10,4400    285 https://www.mbusa.com/en/vehicles/class/eqs
Mercedes-Benz   EQE SUV $77,900 235 https://www.mbusa.com/en/vehicles/class/eqe/suv
Nissan	LEAF	$28,140	149	https://www.nissanusa.com/vehicles/electric-cars/leaf.html
Nissan  ARIYA   $39,590 219 https://www.nissanusa.com/vehicles/electric-cars/ariya.html
Polestar	2	$49,900	247	https://www.polestar.com/us/polestar-2
Polestar	3	$73,400	315	https://www.polestar.com/us/polestar-3
Porsche	Taycan	$90,900	206	https://www.porsche.com/usa/models/taycan
Porsche Macan   $60,900 300 https://www.porsche.com/usa/models/macan
Ram	1500 REV	$65,000	350	https://www.ramtrucks.com/electric
Rivian	R1S	$78,000	289	https://rivian.com/r1s
Rivian	R1T	$73,000	270	https://rivian.com/r1t
Subaru	Solterra	$44,995	222	https://www.subaru.com/vehicles/solterra
Tesla	Model X	$79,990	333	https://www.tesla.com/modelx
Tesla	Model S	$74,990	396	https://www.tesla.com/models
Tesla   Model Y $43,990 283 https://www.tesla.com/modely
Tesla   Cybertruck  $57,390 250 https://www.tesla.com/cybertruck
Tesla   Model 3 $38,990 272 https://www.tesla.com/model3
Toyota	bZ4X	$45,150	222	https://www.toyota.com/bz4x/
Volkswagen	ID.4	$39,735	206	https://www.vw.com/en/models/id-4
Volvo	XC40 Recharge	$52,450	254	https://www.volvocars.com/us/cars/xc40-electric
Volvo	EX30	$34,950	275	https://www.volvocars.com/us/cars/ex30
Volvo	C40 Recharge	$53,600	257	https://www.volvocars.com/us/cars/c40-electric
Volvo	EX90	$76,695	300	https://www.volvocars.com/us/cars/ex90
`;

export const CARS_DATA_ARRAY = CARS_DATA.split("\n")
    .map((line) => {
        const [make, model, price, range, url] = line.split("\t");
        return { make, model, price, range, url };
    })
    .filter((car) => car.make && car.model && car.price && car.range && car.url)
    .sort((a, b) => (a.make[0] > b.make[0] ? 1 : -1));
