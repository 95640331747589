import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  InputLabel,
  ThemeProvider,
} from "@mui/material";
import { APP_THEME, STEPS } from "../App";
import { ApiHandler, trackApiCall as submitUserInfo } from "../ApiHandler";
import RevoLogo from "components/RevoLogo";

function UserInfo({ setShowStep, userInfo, setUserInfo, selection, dealers, isCalculator }) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const showAddress = ["GM", "Volvo", "Subaru"].includes(selection.make);

  useEffect(() => {
    setError("");
  }, [userInfo]);

  const handleSubmit = () => {
    if (
      !userInfo.firstName ||
      !userInfo.email 
    ) {
      setError("Please fill out all required fields.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userInfo.email)) {
      setError("Invalid Email");
      return;
    }

    setLoading(true);
    setError("");

    if (isCalculator) {
      ApiHandler.sendSelectedDealers(
        userInfo,
        selection,
        userInfo.selected.map((uuid) =>
          dealers.find((dealer) => dealer.uuid === uuid)
        )
      );
    } else {
      window.dataLayer.push({ event: "dealers_submit" });
      submitUserInfo({
        user: userInfo,
      });
    }
   

    // otherwise it looks strange the instant change
    ApiHandler.sleep().then(() => {
      setLoading(false);
      setShowSuccess(true);
    });
  };

  const isRequiredInputError = error === "Please fill out all required fields.";

  return (
    <ThemeProvider theme={APP_THEME}>
      <div
        className={`container blue-step {isCalculator ? "has-banner-above" : ""}`}
        style={{ height: "100%", paddingTop: 30 }}
      >
        <div>
        <div className="header">See How You Can Save With rEVo</div>
        <div className="primary-text">
          <p className="p-text">Revo works with dealers so you don’t have to. We find exclusive offers and deals on your behalf. Enter your information below to see how you can save
          </p>
        </div>

          <div className="row">
            <div className="column">
              <InputLabel className="label">Name*</InputLabel>
              <TextField
                placeholder="Sam"
                value={userInfo.firstName}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, firstName: e.target.value })
                }
                onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
                error={isRequiredInputError && !userInfo.firstName}
              />
            </div>
            <div className="column">
              <InputLabel className="label">Email*</InputLabel>
              <TextField
                placeholder="sam.smith@gmail.com"
                type="email"
                value={userInfo.email}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, email: e.target.value })
                }
                onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
                error={(isRequiredInputError && !userInfo.email) || error === "Invalid Email"}
              />
            </div>
          </div>

          {showAddress && (
            <div className="row">
              <div className="column">
                <InputLabel className="label">Street Address*</InputLabel>
                <TextField
                  placeholder="123 Main St."
                  value={userInfo.lastName}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, address: e.target.value })
                  }
                  onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
                />
              </div>
            </div>
          )}
        </div>

        <div className="row">
          <div className="column">
            {!showSuccess && <Button
              className="submit-button"
              variant="contained"
              color="custom_black"
              sx={{ fontWeight: "bold", padding: 1.5 }}
              onClick={handleSubmit}
            >
              {loading ? (
                <div className="spinner"></div>
              ) : (
                "GET EXCLUSIVE OFFERS"
              )}
            </Button>}
            {showSuccess && (
              <div className="success">
                Thank you! We will be in touch shortly.
              </div>
            )}
            {error && <div className="error">{error}</div>}
          </div>
        </div>

        <div className={isCalculator && "row-stretch"}>
          {isCalculator && (
            <div className="link" onClick={() => setShowStep(STEPS.SELECT_MAKE)}>
            Go back
          </div>)}
          <div>
            <RevoLogo />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default UserInfo;
