import React, { useCallback, useEffect, useState } from "react";

const TERM_IN_MONTHS = 36;
const RESIDUAL_VALUE_PERCENT = 0.62;
const MONEY_FACTOR = 0.003733;
const DEALER_FEES = 750;
const FEDERAL_TAX_CREDIT = 7500;
const SALES_TAX_RATE = 0.0625; // Replace with the actual sales tax rate

const TopSectionCalc = ({ lease }) => {
  const [withTaxCredit, setWithTaxCredit] = useState(0);
  const [withoutTaxCredit, setWithoutTaxCredit] = useState(0);

  const getMonthlyPayment = useCallback((purchasePrice, taxCredit) => {
    const grossCapCost =
      purchasePrice + DEALER_FEES - (taxCredit ? FEDERAL_TAX_CREDIT : 0);
    const netCapCost = grossCapCost - lease.downPayment - lease.tradeInValue;
    const residualValue = purchasePrice * RESIDUAL_VALUE_PERCENT;
    const depreciation = netCapCost - residualValue;
    const rentCharge =
      (netCapCost + residualValue) * MONEY_FACTOR * TERM_IN_MONTHS;
    const salesTax = (depreciation + rentCharge) * SALES_TAX_RATE;
    const salesTaxOnLease = salesTax / TERM_IN_MONTHS;
    const calcLeasePayment = (depreciation + rentCharge) / TERM_IN_MONTHS;
    const totalLeasePayment = salesTaxOnLease + calcLeasePayment;

    return totalLeasePayment;
  }, [lease.downPayment, lease.tradeInValue]);

  useEffect(() => {
    if (lease.price) {
      setWithoutTaxCredit(getMonthlyPayment(lease.price));
      setWithTaxCredit(getMonthlyPayment(lease.price, true));
    }
  }, [lease, getMonthlyPayment]);

  return (
    <div className="top_section_calc center">
      <div>
        <div className="title">
          What a great deal! Now find an EV near you
        </div>
        <div className="row">
          <div className="column">
            <div className="big_number text-black">
              ${withTaxCredit.toFixed(0)}
            </div>
            <div style={{ fontSize: 12 }}>With federal tax credit</div>
          </div>
          <div className="column">
            <div
              className="big_number text-gray"
              style={{ textDecoration: "line-through" }}
            >
              ${withoutTaxCredit.toFixed(0)}*
            </div>
            <div style={{ fontSize: 12 }}>
              *Without federal tax credit
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSectionCalc;
